import React from "react";
import { graphql } from "gatsby";
import { Wrapper } from "../components/site/Wrapper";
import { Image } from "../components/site/Image";
import { Button } from "../components/site/Button";

const About = (props) => {
  // console.log(props);
  const books = props?.data?.allBooksJson?.edges;

  return (
    <Wrapper>
      <div className="container gx-1">
        <h1 className="fw-bold mx-2 col-lg-8">About Us</h1>

        <p className="my-3 mx-2 col-lg-8">
          We are a company founded in 2022 with the goal of providing top-notch word search and other puzzle books to people of all ages. We believe that puzzles are a fantastic way to keep the mind sharp and active, and our books are created to offer a challenging but enjoyable experience for our readers.
        </p>
        <p className="my-3 mx-2 col-lg-8">
        Our puzzle books are printed using the same high-quality printers that are used by some of the most reputable brands in the industry. This ensures that our books are of the highest quality and are easy to read. Our product lines include a wide range of puzzle types, catering to all skill levels, from beginners to experienced puzzlers, and we have a book that will suit your needs.
        </p>
        <p className="my-3 mx-2 col-lg-8">
        In addition to our puzzle books, we also offer a variety of online resources and tools to help our customers get the most out of their puzzle-solving experience. This includes a user-friendly website where customers can browse our selection of puzzle books and access a wide variety of online puzzles. These puzzles are designed to be both challenging and enjoyable, catering to a range of skill levels and interests. We also continuously update our website with new and exciting puzzles to keep our customers engaged.
          </p>
        <p className="my-3 mx-2 col-lg-8">
        At PuzzleBase Games, customer satisfaction is our top priority. We understand that everyone has different preferences when it comes to puzzles, which is why we offer a wide variety of puzzle books and online puzzles to choose from. Our team of experienced puzzle designers work hard to create high-quality and challenging puzzles that will keep our customers engaged and entertained for hours. We are dedicated to providing the best possible service to our customers and we are always open to feedback and suggestions. If you ever have any questions or concerns, please don't hesitate to contact us. We will do everything we can to make sure you are completely satisfied with your PuzzleBase Games experience.
        </p>
        <p className="my-3 mx-2 col-lg-8">
        Thank you for choosing PuzzleBase Games and we hope you enjoy our puzzles as much as we do!
        </p>
      </div>
    </Wrapper>
  );
};

export const query = graphql`
  # query will go here
  {
    allBooksJson {
      edges {
        node {
          title
          subTitle
          slug
          image
          amazonUrl
          amazonPrice
          shortUrl
          pages
        }
      }
    }
  }
`;

export default About;
